var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"addon-dark ant-input-group-wrapper h-48px",staticStyle:{"max-width":"600px"}},[_c('div',{staticClass:"ant-input-wrapper ant-input-group",class:{ 'has-error': _vm.error }},[_c('div',{staticClass:"ant-input-group-addon bg-white"},[_c('FormItem',{staticStyle:{"width":"80px","margin":"0 -11px","border-radius":"3px 0 0 3px"},attrs:{"tag":"div","rules":"required","name":"currency"}},[_c('a-select',{staticClass:"w-100 text-dark",attrs:{"options":_vm.$route.meta.title === 'Powerbiz - Add Product Redeem' || _vm.$route.meta.title === 'Powerbiz - Edit Product Redeem'
            ? _vm.coin.map(currency => ({
              value: currency.value,
              label: currency.symbol,
            })) : _vm.newCurrencies.map(currency => ({
              value: currency.value,
              label: currency.symbol,
            })),"disabled":_vm.$route.query.edit ? true : false},model:{value:(_vm.price.currency),callback:function ($$v) {_vm.$set(_vm.price, "currency", $$v)},expression:"price.currency"}})],1)],1),_c('ValidationProvider',{ref:"priceValidationProvider",attrs:{"tag":"div","rules":"required","name":_vm.label}},[_c('a-input-number',{staticClass:"h-48px w-100 required price-input-number-input",attrs:{"disabled":_vm.disabled,"formatter":value => _vm.format(value),"parser":value => _vm.parseLocaleNumber(value)},model:{value:(_vm.price.value),callback:function ($$v) {_vm.$set(_vm.price, "value", _vm._n($$v))},expression:"price.value"}})],1),_c('div',{staticClass:"ant-input-group-addon"},[(!_vm.unit)?_c('FormItem',{staticClass:"text-white",staticStyle:{"width":"135px","margin":"0 -11px"},attrs:{"tag":"div","rules":"required"}},[_c('UnitSelector',{attrs:{"disabled":_vm.$route.query.edit ? true : false},on:{"change":val => _vm.$emit('changeuom', val)},model:{value:(_vm.price.uom),callback:function ($$v) {_vm.$set(_vm.price, "uom", $$v)},expression:"price.uom"}})],1):_c('div',{staticClass:"text-white text-left px-3",staticStyle:{"width":"135px","margin":"0 -11px"}},[_vm._v(" "+_vm._s(_vm.unit)+" ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }