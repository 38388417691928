<script>
import { computed, defineComponent, ref, watch } from 'vue'
import FormItem from '@/components/Input/FormItem.vue'
import Price from './Price/index.vue'

export default defineComponent({
  components: {
    FormItem,
    Price,
},
  props: {
    business: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const model = ref(props.value)
    watch(() => props.value, (value) => {
      model.value = value
    }, { immediate: true })
    const item = computed({
      get() {
        return model.value.catalogs.items[0] || {}
      },
      set(value) {
        model.value.catalogs.items[0] = value
      },
    })

    const toCurrency = (value) => {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    }

    return {
      model,
      item,
      toCurrency,
    }
  },
})
</script>

<template>
  <div>
    <div>
      <div v-if="$route.query.edit">
        <a-col class="mb-2" :span="6">
          <div class="title-detail d-flex justify-content-start mb-2">
            {{ $t('SKU') }}
          </div>
        </a-col>
        <a-col class="mb-2" :span="18">
          <div class="mb-2">
            : {{ item.sku }}
          </div>
        </a-col>
        <a-col v-if="!edit && model.catalogs.items.length === 1" class="mb-2" :span="6">
          <div class="title-detail d-flex justify-content-start mb-2">
            Harga
          </div>
        </a-col>
        <a-col v-if="!edit && model.catalogs.items.length === 1" class="mb-2" :span="18">
          <div class="mb-2">
            : {{ item.price.currency === 'IDR' ? 'Rp. ' : '$' }} {{ toCurrency(item.price.value) }}
          </div>
        </a-col>
        <a-col v-if="!edit && model.catalogs.items.length === 1" class="mb-2" :span="6">
          <div class="title-detail d-flex justify-content-start mb-2">
            Satuan
          </div>
        </a-col>
        <a-col v-if="!edit && model.catalogs.items.length === 1" class="mb-2" :span="18">
          <div class="mb-2">
            : {{ item.price.uom }}
          </div>
        </a-col>
      </div>
      <div v-else style="max-width: 600px">
        <a-row type="flex" :gutter="16">
          <a-col :lg="{ span: 24 }">
            <FormItem rules="required|no_space" :label="$t('SKU')">
              <a-input 
                v-model="item.sku"
                class="h-48px"
                style="text-transform: uppercase"
                @keyup="e => e.target.value = e.target.value.toUpperCase()"
              />
            </FormItem>
          </a-col>
        </a-row>
      </div>

      <div v-if="!$route.query.edit || edit">
        <label class="mb-2">Harga :</label>
        <Price
          v-model="item.price"
          :business="business"
          :units="model.units"
          :edit="edit"
          :vat-type="model.detail.vat_type" 
          class="mb-5"
        />
      </div>
    </div>
  </div>
</template>
