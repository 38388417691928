<script>
import { defineComponent, ref, watch, nextTick, getCurrentInstance } from 'vue'
import { ValidationObserver } from 'vee-validate'
import { useSaveSectionOnScroll } from './../useSaveSectionOnScroll'
import FormItem from '@/components/Input/FormItem.vue'
import Checkbox from '@/components/Input/Checkbox.vue'
import SingleVariant from './SingleVariant.vue'
import MultiVariant from './MultiVariant/index.vue'
import isEqual from 'lodash/isEqual'
import route from '@/router'

/**
 * @template T
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 */
/**
 * @typedef {import('@/types/product').ProductFormModel} ProductFormModel
 */
export default defineComponent({
  name: 'TypeAndPrice',
  components: {
    ValidationObserver,
    FormItem,
    Checkbox,
    SingleVariant,
    MultiVariant,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    submitting: {
      type: Boolean,
      required: true,
    },
    permission: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $confirm } = getCurrentInstance().proxy.$root

    const { dirty, el, validationObserver } = useSaveSectionOnScroll(emit)

    /** @type {Ref<ProductFormModel} */
    const model = ref(props.value)
    const edit = ref(false)
    watch(() => props.value, (product) => {
      model.value = product
    }, { deep: true, immediate: true })
    watch(() => model.value.catalogs, (catalog, old) => {
      isUseVariation.value = !!catalog.attributes && Object.keys(catalog.attributes).length > 0
      if (!isEqual(catalog, old)) {
        nextTick(() => emit('input', model.value))
      }
    }, { deep: true })
    watch(() => [model.value.detail.title, model.value.catalogs.attributes], () => {
      model.value.catalogs.items.forEach(item => {
        item.title = [
          model.value.detail.title,
          (Object.values(item.option || {}) || []).join(', '),
        ].filter(Boolean).join(' - ')
      })
    }, { deep: true })


    const isUseVariation = ref(!!props.value.catalogs.options)
    watch(isUseVariation, (value) => {
      if (!value) {
        model.value.catalogs = {
          ...model.value.catalogs,
          attributes: {},
          options: [],
          items: [{
            ...model.value.catalogs.items[0],
            title: model.value.detail.title,
            price: {
              ...model.value.catalogs.items[0].price,
              currency: route.app._route.meta.title === 'Powerbiz - Add Product Redeem' || route.app._route.meta.title === 'Powerbiz - Edit Product Redeem'
                ? 'Koin' : model.value.catalogs.items[0].price.currency,
            },
          }],
        }
      }
    }, { immediate: true })
    const useVariationTimestamp = ref(0)
    const onUseVariationChange = (value) => {
      useVariationTimestamp.value = Date.now() // hack to force update :)
      if (isUseVariation.value && !value) {
        $confirm({
          icon: () => null,
          title: 'Hapus Varian',
          content: 'Jika dirubah, data varian yang telah dimasukkan akan berubah dan tipe varian akan hilang dari pilihan varian, lho.',
          okText: 'Ya, Hapus',
          onOk: () => isUseVariation.value = value,
        })
      } else {
        isUseVariation.value = value
      }
    }

    const handleEdit = () => {
      edit.value = !edit.value
      emit('changeEditable', {price: edit.value})
      if (edit.value === false) {
        model.value.catalogs.items = props.catalogs
        const firstErrorElement = document.querySelector('#price-product')
        // submit.value = false
        window.scrollTo({
          behavior: 'smooth',
          top: firstErrorElement.getBoundingClientRect().top + window.pageYOffset - 225,
        })
      }
    }

    const submitEdit = () => {
      let skus = []

      model.value.catalogs.items.forEach(catalog => {
        skus.push({
          id: catalog.channel_catalog_id,
          original_price: catalog.price.value,
          seller_sku: catalog.sku,
          sales_attributes: catalog.attributes.map(item => ({
            attribute_name: item.name,
            sku_img: { id: catalog.images?.[0]?.id },
          })),
        })
      })
      
      let payload = {
        section: 3,
        skus,
      }
      emit('saveEdit', payload)
    }

     watch(() => props.submitting, (value) => {
        if (!value && edit.value ) {
          edit.value = false
          emit('changeEditable', {price: false})
          const firstErrorElement = document.querySelector('#price-product')
          window.scrollTo({
            behavior: 'smooth',
            top: firstErrorElement?.getBoundingClientRect().top + window.pageYOffset - 225,
          })
        }
      }, { deep: true, immediate: true })

    return {
      el,
      dirty,
      validationObserver,
      model,
      isUseVariation,
      onUseVariationChange,
      useVariationTimestamp,
      edit,
      handleEdit,
      submitEdit,
    }
  },
})
</script>

<template>
  <a-card ref="el">
    <a-skeleton v-if="loading" active />
    <div v-else>
      <ValidationObserver ref="validationObserver">
        <div class="d-flex">
          <div>
            <h4 id="price-product" class="">
              {{ $t('product.product_type_and_price') }}
            </h4>
          </div>
          <div v-if="$route.query.edit && !edit && permission.includes('WRITE')" class="ml-auto">
            <a-button
              type="primary"
              size="large"
              :ghost="edit ? false : true"
              @click="handleEdit"
            >
              Edit
            </a-button>
          </div>
        </div>
        <div class="text-muted mb-4">
          {{ $t('product.variant_desc') }}
        </div>

        <!-- <FormItem v-if="$route.query.edit">
          <div class="vat-options">
            <label>
              <input v-model="model.detail.vat_type" type="radio" name="vat" :value="model.detail.vat_type" />
              <div>{{ $t('product.price-include-vat') }}</div>
            </label>
          </div>
        </FormItem>
        <FormItem v-else>
          <div class="vat-options">
            <label>
              <input v-model="model.detail.vat_type" type="radio" name="vat" value="INCLUDE" />
              <div>{{ $t('product.price-include-vat') }}</div>
            </label>
            <label>
              <input v-model="model.detail.vat_type" type="radio" name="vat" value="EXCLUDE" />
              <div>{{ $t('product.price-exclude-vat') }}</div>
            </label>
            <label>
              <input v-model="model.detail.vat_type" type="radio" name="vat" value="NONE" />
              <div>{{ $t('product.price-no-vat') }}</div>
            </label>
          </div>
        </FormItem>-->

        <div v-if="$route.query.edit">
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              Tipe Produk
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ model.variant_count ? 'Varian' : 'Non Varian' }}
            </div>
          </a-col>
        </div>

        <div v-else>
          <FormItem :key="useVariationTimestamp" tag="div">
            <Checkbox :value="isUseVariation" :label="$t('product.product_variant')" @input="(value) => onUseVariationChange(value)" />
          </FormItem>
        </div>

        <div v-if="!$route.query.edit">
          <section v-if="!isUseVariation">
            <SingleVariant v-model="model" :business="business" :edit="edit" @handleEdit="handleEdit" />
          </section>

          <section v-else>
            <MultiVariant
              v-model="model"
              :business="business"
              @changeuom="val => $emit('changeuom', val)"
            />
          </section>
        </div>
        <div v-else>
          <section v-if="!model.variant_count">
            <SingleVariant v-model="model" :business="business" :edit="edit" @handleEdit="handleEdit" />
          </section>

          <section v-else>
            <MultiVariant
              v-model="model"
              :business="business"
              :edit="edit" 
              @handleEdit="handleEdit"
              @changeuom="val => $emit('changeuom', val)"
            />
          </section>
        </div>
      </ValidationObserver>
      <div v-if="$route.query.edit && edit" class="mt-3 py-3 text-right footer">
        <a-button
          size="large"
          type="primary"
          ghost
          class="px-5 mr-3 ml-auto"
          :loading="submitting"
          @click="handleEdit"
        >
          {{ $t('utils.cancel') }}
        </a-button>

        <a-button
          size="large"
          type="primary"
          class="px-5"
          :loading="submitting"
          @click="submitEdit"
        >
          {{ $t('utils.save') }}
        </a-button>
      </div>
    </div>
  </a-card>
</template>

<style scoped lang="scss">
.vat-options {
  margin-left: -.5rem;
  margin-right: -.5rem;

  input {
    display: none;
  }

  label {
    padding: 0 .5rem;
    line-height: 1.5;

    div {
      padding: .35rem 1rem;
      border: 1px solid #4D4D4D;
      border-radius: 5rem;
      cursor: pointer;
      transition: .1s all ease-in;
    }

    input:checked {
      + div {
        background: #4D4D4D;
        color: #fff;
      }
    }
  }
}
</style>
