const buildTree = (flatArray, id, parent) => {
  const nodeMap = new Map()
  for (const node of flatArray) {
    node.children = []
    nodeMap.set(node[id], node)
  }

  const tree = []
  for (const node of flatArray) {
    if (node[parent] == 0 || node[parent] === null || node[parent] === undefined) {
      tree.push(node)
    } else {
      const parentNode = nodeMap.get(node[parent])
      if (parentNode) parentNode.children.push(node)
    }
  }

  return tree
}

export default buildTree
