import apiClient from '@/services/axios'

export const getWarehouseTiktok = ({ channel_id, business_id }) => {
  return apiClient({
    method: 'get',
    url: `/channel/tiktok/integration/query/warehouse/map/list/${channel_id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const checkTiktokSyncStockStatus = ({ business_id, channel_code, channel_id }) => {
  return apiClient({
    method: 'get',
    url: `channel/${channel_code}/product/query/${channel_id}/sync/latest-process`,
    headers: {
      'Business-Id': business_id,
    },
  })
}